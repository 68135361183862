<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="3">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-align-justify"></i><strong> Yetki Kategorileri </strong>
            </div>
            <b-nav vertical>
              <b-nav-item v-if="permission_tree.list" v-for="(permission1, permission_ind1) in permission_tree.list" :key="permission_ind1">
                <div v-if="is_edit_perm.value" :style="permission_ind1 === selected_permission_ind1 ? 'color:red; font-weight:bold;' : ''">
                  {{ permission1.no ? permission1.no + '-' : '' }} {{ permission1.label }}
                </div>
                <div v-else :style="permission_ind1 === selected_permission_ind1 ? 'color:red; font-weight:bold;' : ''" @click="SelectPermission(1, permission_ind1)">
                  {{ permission1.no ? permission1.no + '-' : '' }} {{ permission1.label }}
                </div>
                <b-nav vertical v-if="permission_ind1 === selected_permission_ind1">
                  <b-nav-item v-if="permission1.list" v-for="(permission2, permission_ind2) in permission1.list" :key="permission_ind2">
                    <div v-if="is_edit_perm.value" :style="permission_ind2 === selected_permission_ind2 ? 'color:red; font-weight:bold;' : ''">
                      {{ permission2.no ? permission2.no + '-' : '' }} {{ permission2.label }}
                    </div>
                    <div v-else :style="permission_ind2 === selected_permission_ind2 ? 'color:red; font-weight:bold;' : ''" @click="SelectPermission(2, permission_ind1, permission_ind2)">
                      {{ permission2.no ? permission2.no + '-' : '' }} {{ permission2.label }}
                    </div>
                    <b-nav vertical v-if="permission_ind2 === selected_permission_ind2">
                      <b-nav-item v-if="permission2.list" v-for="(permission3, permission_ind3) in permission2.list" :key="permission_ind3">
                        <div v-if="is_edit_perm.value" :style="permission_ind3 === selected_permission_ind3 ? 'color:red; font-weight:bold;' : ''">
                          {{ permission3.no ? permission3.no + '-' : '' }} {{ permission3.label }}
                        </div>
                        <div v-else :style="permission_ind3 === selected_permission_ind3 ? 'color:red; font-weight:bold;' : ''" @click="SelectPermission(3, permission_ind1, permission_ind2, permission_ind3)">
                          {{ permission3.no ? permission3.no + '-' : '' }} {{ permission3.label }}
                        </div>
                      </b-nav-item>
                      <b-nav-item>
                        <b-button @click="add_new_permission_cat(3, permission_ind1, permission_ind2)" variant="secondary" size="sm"> <i class="fa fa-plus"></i> ekle ({{ permission2.no }}) </b-button>
                      </b-nav-item>
                    </b-nav>
                  </b-nav-item>
                  <b-nav-item>
                    <b-button @click="add_new_permission_cat(2, permission_ind1)" variant="secondary" size="sm"> <i class="fa fa-plus"></i> ekle ({{ permission1.no }}) </b-button>
                  </b-nav-item>
                </b-nav>
              </b-nav-item>
              <b-nav-item>
                <b-button @click="add_new_permission_cat(1)" variant="secondary" size="sm"> <i class="fa fa-plus"></i> ekle</b-button>
              </b-nav-item>
            </b-nav>
          </b-card>
        </b-col>
        <b-col cols="9">
          <b-card footer-tag="footer" header-tag="header" v-if="selected_permission_ind1 !== 'none'">
            <template slot="footer">
              <b-button @click="save_permission_data()" variant="success"> <i class="fa fa-save"></i> Kaydet</b-button>
              <b-button @click="reset_permission_data()" variant="warning"> <i class="fa fa-stop"></i> Reset</b-button>
            </template>
            <div slot="header">
              <i class="fa fa-align-justify"></i><strong> Yetkilendirmeler </strong>
            </div>
            <!-- asdasdasdasd -->
            <b-row>
              <b-col cols="12">
                <b-form-group label="Yetki Grubu Başlığı" label-for="permission_label" label-cols="3" validated>
                  <template>
                    <b-form-input type="text" class="form-control-warning" id="permission_label" v-model="permission_data.label" required></b-form-input>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>Lütfen yetki grubu başlığını giriniz</b-form-invalid-feedback>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-card class="card-accent-primary">
                  <b-row>
                    <b-col sm="1" md="1"></b-col>
                    <b-col sm="5" md="3">
                      Yetki Kodu
                    </b-col>
                    <b-col sm="6" md="3">
                      Yetki Adı
                    </b-col>
                    <b-col sm="2" md="1">
                      Superuser
                    </b-col>
                    <b-col sm="3" md="1">
                      Doktor
                    </b-col>
                    <b-col sm="2" md="1">
                      Hemşire
                    </b-col>
                    <b-col sm="3" md="1">
                      Sekreter
                    </b-col>
                    <b-col sm="2" md="1">
                      Eczacı
                    </b-col>
                  </b-row>
                  <template v-for="(perm, perm_ind) in permission_data.permission">
                    <b-row>
                      <b-col sm="1" md="1">
                        <template v-if="!is_edit_perm.value">
                          <b-dropdown variant="primary" style="width: 100%; padding: 3px;">
                            <b-dropdown-item @click="editPerm(perm_ind, perm.value)">Düzenle</b-dropdown-item>
                            <b-dropdown-item @click="deletePerm(perm_ind)">Sil</b-dropdown-item>
                            <b-dropdown-item v-if="perm_ind !== 0" @click="moveUpPerm(perm_ind)">Bir Yukarı Taşı</b-dropdown-item>
                            <b-dropdown-item v-if="perm_ind !== (permission_data.permission.length - 1)" @click="moveDownPerm(perm_ind)">Bir Aşağı Taşı</b-dropdown-item>
                          </b-dropdown>
                        </template>
                        <template v-else-if="is_edit_perm.value && is_edit_perm.id === perm_ind">
                          <b-button block variant="primary" @click="editPermSave(perm_ind, perm.value)"><i class="fa fa-save"></i></b-button>
                        </template>
                      </b-col>
                      <b-col sm="5" md="3">
                        <template v-if="is_edit_perm.value && is_edit_perm.id === perm_ind">
                          <b-form-input type="text" class="form-control-warning" id="permission_label" v-model="perm.value"></b-form-input>
                        </template>
                        <template v-else>
                          {{ perm.value }}
                        </template>
                      </b-col>
                      <b-col sm="6" md="3">
                        <template v-if="is_edit_perm.value && is_edit_perm.id === perm_ind">
                          <b-form-input type="text" class="form-control-warning" id="permission_label" v-model="perm.label"></b-form-input>
                        </template>
                        <template v-else>
                          {{ perm.label }}
                        </template>
                      </b-col>
                      <b-col sm="2" md="1">
                        <b-form-checkbox :id="perm.value + '_superuser'" value="superuser" v-model="perm.default"></b-form-checkbox>
                      </b-col>
                      <b-col sm="3" md="1">
                        <b-form-checkbox :id="perm.value + '_doctor'" value="doctor" v-model="perm.default"></b-form-checkbox>
                      </b-col>
                      <b-col sm="2" md="1">
                        <b-form-checkbox :id="perm.value + '_nurse'" value="nurse" v-model="perm.default"></b-form-checkbox>
                      </b-col>
                      <b-col sm="3" md="1">
                        <b-form-checkbox :id="perm.value + '_secretary'" value="secretary" v-model="perm.default"></b-form-checkbox>
                      </b-col>
                      <b-col sm="2" md="1">
                        <b-form-checkbox :id="perm.value + '_pharmacy'" value="pharmacy" v-model="perm.default"></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                  <b-row>
                    <b-col cols="12">&nbsp;</b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">&nbsp;</b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="1" md="1">
                      <b-button block variant="primary" v-if="valueRegexCheck && new_empty_permission.label !== '' && new_empty_permission.value !== ''" @click="new_empty_permission_add()"><i class="fa fa-plus"></i></b-button>
                    </b-col>
                    <b-col sm="5" md="3">
                      <b-form-group :state="valueRegexCheck">
                        <b-form-input type="text" class="form-control-warning" id="permission_label" placeholder="Yetki Kodu Giriniz" v-model="new_empty_permission.value" :formatter="toLowerCase" :state="valueRegexCheck"></b-form-input>
                        <b-form-valid-feedback></b-form-valid-feedback>
                        <b-form-invalid-feedback>Kullanabileceğiniz karakterler: a-z 0-9 - _</b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="5" md="3">
                      <b-form-group validated>
                        <b-form-input type="text" class="form-control-warning" id="permission_label" placeholder="Yetki Adı Giriniz" v-model="new_empty_permission.label" required></b-form-input>
                        <b-form-valid-feedback></b-form-valid-feedback>
                        <b-form-invalid-feedback>Lütfen yeni yetkinin adını giriniz</b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="1" md="1">
                      <b-button block variant="primary" v-if="valueRegexCheck && new_empty_permission.label !== '' && new_empty_permission.value !== ''" @click="new_empty_permission_add()"><i class="fa fa-plus"></i></b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PermissionService from '@/services/permission';
import {
  default as Modal
} from '@/components/widgets/Modal';

export default {
  name: 'PermissionList',
  components: {
    Modal
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      device: 'device'
    }),
    valueRegexCheck () {
      const regex = /^[\-\_a-z0-9]*$/ig;
      if (this.new_empty_permission.value) {
        let regexValue = regex.exec(this.new_empty_permission.value);
        if (regexValue && (this.new_empty_permission.value === regexValue[0])) {
          return true;
        }
      }
      return false;
    },
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.value)
    }
  },
  data () {
    return {
      is_edit_perm: { 'value': false, 'id': '' },
      new_empty_permission: { 'value': '', 'label': '' },
      permission_tree_bck_data: {},
      selected_page_permission_list_ind: '',
      permission_page_list: [],
      selected_permission_ind1: 'none',
      selected_permission_ind2: 'none',
      selected_permission_ind3: 'none',
      selected_level: '',
      permission_tree: {},
      permission_data: { 'value': '', 'label': '', 'permission': [] },
      user: {}
    }
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
    this.get_permission_tree();
  },
  methods: {
    editPerm (perm_ind) {
      this.is_edit_perm.value = true;
      this.is_edit_perm.id = perm_ind;
      this.$forceUpdate();
    },
    editPermSave (perm_ind, new_perm_value) {
      if (this.isPermValueUsable(new_perm_value, this.selected_level, this.selected_permission_ind1, this.selected_permission_ind2, this.selected_permission_ind3, perm_ind)) {
        this.is_edit_perm.value = false;
      } else {
        alert('Kayıt işlemi mutlaka düzenleme yapılan yetki satırında tıklanılmalı ve value benzersiz olmalı');
      }
    },
    deletePerm (perm_ind) {
      this.permission_data.permission.splice(perm_ind, 1);
      this.$forceUpdate();
    },
    moveUpPerm (perm_ind) {
      let tmp = this.permission_data.permission[perm_ind];
      this.permission_data.permission[perm_ind] = this.permission_data.permission[perm_ind - 1];
      this.permission_data.permission[perm_ind - 1] = tmp;
      this.$forceUpdate();
    },
    moveDownPerm (perm_ind) {
      let tmp = this.permission_data.permission[perm_ind];
      this.permission_data.permission[perm_ind] = this.permission_data.permission[perm_ind + 1];
      this.permission_data.permission[perm_ind + 1] = tmp;
      this.$forceUpdate();
    },
    toLowerCase (value, event) {
      return value.toLowerCase()
    },
    isPermValueUsable (value, total_level = '', level_1_ind = '', level_2_ind = '', level_3_ind = '', perm_ind = '') {
      for (let list_ind_1 in this.permission_tree.list) {
        let list_1 = this.permission_tree.list[list_ind_1];
        for (let perm_ind_1 in list_1.permission) {
          if (total_level === 1 && level_1_ind === parseInt(list_ind_1) && perm_ind === parseInt(perm_ind_1)) {
            continue;
          } else {
            let perm_1 = list_1.permission[perm_ind_1];
            if (value === perm_1.value) {
              return false;
            }
          }
        }
        for (let list_ind_2 in list_1.list) {
          let list_2 = list_1.list[list_ind_2];
          for (let perm_ind_2 in list_2.permission) {
            if (total_level === 2 && level_1_ind === parseInt(list_ind_1) && level_2_ind === parseInt(list_ind_2) && perm_ind === parseInt(perm_ind_2)) {
              continue;
            } else {
              let perm_2 = list_2.permission[perm_ind_2];
              if (value === perm_2.value) {
                return false;
              }
            }
          }
          for (let list_ind_3 in list_2.list) {
            let list_3 = list_2.list[list_ind_3];
            for (let perm_ind_3 in list_3.permission) {
              if (total_level === 3 && level_1_ind === parseInt(list_ind_1) && level_2_ind === parseInt(list_ind_2) && level_3_ind === parseInt(list_ind_3) && perm_ind === parseInt(perm_ind_3)) {
                continue;
              } else {
                let perm_3 = list_3.permission[perm_ind_2];
                if (value === perm_3.value) {
                  return false;
                }
              }
            }
          }
        }
      }
      return true;
    },
    new_empty_permission_add: function () {
      if (this.isPermValueUsable(this.new_empty_permission.value)) {
        let tmp_perm_data = { 'value': this.new_empty_permission.value, 'label': this.new_empty_permission.label, 'default': [] };
        this.permission_data.permission.push(tmp_perm_data);
        this.new_empty_permission = { 'value': '', 'label': '' };
      } else {
        alert('Value benzersiz olmalı');
      }
    },
    SelectPermission (level, permission_ind1, permission_ind2 = '', permission_ind3 = '') {
      this.permission_data = { 'value': '', 'label': '', 'permission': [] };
      this.selected_level = level;
      this.selected_permission_ind1 = permission_ind1;
      this.selected_permission_ind2 = permission_ind2;
      this.selected_permission_ind3 = permission_ind3;

      if (this.selected_level === 1) {
        this.permission_data = this.permission_tree.list[this.selected_permission_ind1];
      } else if (this.selected_level === 2) {
        this.permission_data = this.permission_tree.list[this.selected_permission_ind1].list[this.selected_permission_ind2];
      } else if (this.selected_level === 3) {
        this.permission_data = this.permission_tree.list[this.selected_permission_ind1].list[this.selected_permission_ind2].list[this.selected_permission_ind3];
      }
    },
    add_new_permission_cat: function (level, permission_ind1 = '', permission_ind2 = '') {
      this.selected_permission_ind1 = '';
      this.selected_permission_ind2 = '';
      this.selected_permission_ind3 = '';
      this.selected_level = level;
      if (level === 1) {
        if (!this.permission_tree.list) {
          this.permission_tree.list = [];
        }
        this.permission_tree.list.push({ 'label': 'Yeni', 'permission': [], 'list': [] })
        this.selected_permission_ind1 = this.permission_tree.list.length - 1;
      } else if (level === 2) {
        if (!this.permission_tree.list[permission_ind1].list) {
          this.permission_tree.list[permission_ind1].list = [];
        }
        this.permission_tree.list[permission_ind1].list.push({ 'label': 'Yeni', 'permission': [], 'list': [] })
        this.selected_permission_ind1 = permission_ind1;
        this.selected_permission_ind2 = this.permission_tree.list[permission_ind1].list.length - 1;
      } else if (level === 3) {
        if (!this.permission_tree.list[permission_ind1].list[permission_ind2].list) {
          this.permission_tree.list[permission_ind1].list[permission_ind2].list = [];
        }
        this.permission_tree.list[permission_ind1].list[permission_ind2].list.push({ 'label': 'Yeni', 'permission': [] })
        this.selected_permission_ind1 = permission_ind1;
        this.selected_permission_ind2 = permission_ind2;
        this.selected_permission_ind3 = this.permission_tree.list[permission_ind1].list[permission_ind2].list.length - 1;
      }
      this.permission_data = { 'label': 'Yeni', 'permission': [], 'list': [] };
      this.$forceUpdate();
      this.SelectPermission(level, this.selected_permission_ind1, this.selected_permission_ind2, this.selected_permission_ind3);
    },
    get_permission_tree: function () {
      PermissionService.get_permission_tree()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.permission_tree = resp.data.result;
            this.permission_tree_bck_data = JSON.parse(JSON.stringify(this.permission_tree));
            if (this.permission_tree.list.length > 0) {
              this.SelectPermission(1, 0);
            }
          }
        });
    },
    save_permission_data: function () {
      if (this.permission_data.label === '') {
        alert('Lütfen alanları doldurunuz');
      } else {
        PermissionService.save_permission_tree(this.permission_tree)
          .then(resp => {
            this.permission_tree = resp.data.result;
            this.permission_tree_bck_data = JSON.parse(JSON.stringify(this.permission_tree));
            this.SelectPermission(this.selected_level, this.selected_permission_ind1, this.selected_permission_ind2, this.selected_permission_ind3);
            alert('kayıt tamamlandı');
          });
      }
    },
    reset_permission_data: function () {
      this.permission_tree = JSON.parse(JSON.stringify(this.permission_tree_bck_data));
      this.SelectPermission(this.selected_level, this.selected_permission_ind1, this.selected_permission_ind2, this.selected_permission_ind3);
    }

  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  },
  watch: {
    'permission_data.label': function () {
      if (this.selected_level) {
        if (this.selected_level === 1) {
          this.permission_tree.list[this.selected_permission_ind1].label = this.permission_data.label;
        } else if (this.selected_level === 2) {
          this.permission_tree.list[this.selected_permission_ind1].list[this.selected_permission_ind2].label = this.permission_data.label;
        } else if (this.selected_level === 3) {
          this.permission_tree.list[this.selected_permission_ind1].list[this.selected_permission_ind2].list[this.selected_permission_ind3].label = this.permission_data.label;
        }
      }
    }
  }
}

</script>

